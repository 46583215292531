import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { ThemeProvider } from "@mui/material";
import { InputTheme } from "../../common/utils/ThemeUtils";
import ClearableTextField from "./ClearableTextField";
import ClearableSpeechLabel from "./ClearableSpeechLabel";
import SpeechTextField from "./SpeechTextField";
import CoverImagePicker from "./CoverImagePicker";
import ChromaImageView from "./ChromaImageView";
import AdditionalImagePicker from "./AdditionalImagePicker";
import RetailPeriodView from "./RetailPeriodView";
import AvailabilityView from "./AvailabilityView";
import PricingView from "./PricingView";
import ConfirmableActionButton from "./ConfirmableActionButton";
import { buildBundles, buildImage, buildMessageBundle } from "../utils/Utils";
import { Path } from "../utils/PathUtils";
import styles from "../style/resource.module.css";

const ProductContentView = (props) => {
  const [name, setName] = useState(props.element?.name?.default);
  const [nameLocales, setNameLocales] = useState(
    buildBundles(props.element?.name?.bundles) || []
  );
  const [description, setDescription] = useState(
    props.element?.description?.default
  );
  const [descriptionLocales, setDescriptionLocales] = useState(
    buildBundles(props.element?.description?.bundles) || []
  );
  const [cover, setCover] = useState(props.element?.image?.cover);
  const [images, setImages] = useState(props.element?.image?.images || []);
  const [startRetailDate, setStartRetailDate] = useState(
    props.element?.startRetailDate
  );
  const [endRetailDate, setEndRetailDate] = useState(
    props.element?.endRetailDate
  );
  const [availability, setAvailability] = useState(
    props.element?.availability || {}
  );
  const [pricing, setPricing] = useState(props.element?.pricing || {});

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <label className={styles.label}>Resources</label>
        <div className={styles.headerControls}>
          <ConfirmableActionButton
            title="abort"
            message="Are you sure you want to discard the changes? Unsaved changes will be lost."
            onClick={() => navigate(Path.RESOURCE)}
          />
          <ConfirmableActionButton
            title="save"
            message="Are you sure you want to proceed with saving the changes?"
            onClick={() => {
              props.onClick({
                name: buildMessageBundle(name, nameLocales),
                description: buildMessageBundle(
                  description,
                  descriptionLocales
                ),
                image: buildImage(cover, images),
                startRetailDate: startRetailDate,
                endRetailDate: endRetailDate,
                availability: availability,
                pricing: pricing,
              });
            }}
          />
        </div>
      </div>
      <Grid
        container
        spacing={3}
        sx={{ overflowY: "scroll" }}
        className={styles.content}
      >
        <ThemeProvider theme={InputTheme}>
          <Grid sx={{ flex: 1 }} key={"product-general-column"}>
            <div className={styles.component}>
              <label className={styles.label}>GENERAL</label>
              {/*product name section*/}
              <label className={styles.text}>Product Name</label>
              <ClearableTextField
                id="default-name-input"
                label="Default Product Name"
                value={name}
                onChange={setName}
              />
              {nameLocales.map((locale) => {
                return (
                  <ClearableSpeechLabel
                    speech={locale.speech}
                    text={locale.value}
                    onRemove={() =>
                      setNameLocales(
                        nameLocales.filter(
                          (nameLocale) => nameLocale.speech !== locale.speech
                        )
                      )
                    }
                  />
                );
              })}
              <SpeechTextField
                id="locale-name-input"
                onChange={(value) => {
                  if (
                    !nameLocales.some(
                      (locale) => locale.speech === value.speech
                    )
                  ) {
                    setNameLocales([value, ...nameLocales]);
                  }
                }}
              />
              {/*product description section*/}
              <label className={styles.text}>Product Description</label>
              <ClearableTextField
                id="default-description-input"
                label="Default Product Description"
                multiline={true}
                value={description}
                onChange={setDescription}
              />
              {descriptionLocales.map((locale) => {
                return (
                  <ClearableSpeechLabel
                    speech={locale.speech}
                    text={locale.value}
                    multiline={true}
                    onRemove={() =>
                      setDescriptionLocales(
                        descriptionLocales.filter(
                          (descLocale) => descLocale.speech !== locale.speech
                        )
                      )
                    }
                  />
                );
              })}
              <SpeechTextField
                id="locale-description-input"
                multiline={true}
                onChange={(value) => {
                  if (
                    !descriptionLocales.some(
                      (locale) => locale.speech === value.speech
                    )
                  ) {
                    setDescriptionLocales([value, ...descriptionLocales]);
                  }
                }}
              />
              {/*product media section*/}
              <label className={styles.text}>Product Media</label>
              <div style={{ display: "flex", marginTop: "2vh" }}>
                <CoverImagePicker
                  src={cover}
                  onSelect={setCover}
                  onRemove={() => setCover(null)}
                />
                <Grid container spacing={1}>
                  {images.map((image, index) => {
                    return [
                      <Grid key={`additional-image-${index}`}>
                        <ChromaImageView
                          src={image}
                          defaultColor="#EEEEEE"
                          size="8vw"
                          onRemove={() =>
                            setImages(images.filter((src) => src !== image))
                          }
                        />
                      </Grid>,
                    ];
                  })}
                  <Grid size={15} key={"additional-image-picker"}>
                    <AdditionalImagePicker
                      onSelect={(image) => setImages([...images, image])}
                    />
                  </Grid>
                </Grid>
              </div>
              {/*product retail period section*/}
              <label className={styles.text}>Product Retail Period</label>
              <RetailPeriodView
                startRetailDate={startRetailDate}
                endRetailDate={endRetailDate}
                onStartRetailDateSelect={setStartRetailDate}
                onEndRetailDateSelect={setEndRetailDate}
              />
            </div>
          </Grid>
          <Grid sx={{ flex: 1 }} key={"product-showcase-column"}>
            <div className={styles.component}>
              <label className={styles.label}>AVAILABILITY</label>
              <AvailabilityView
                availability={availability}
                onSharedChange={(amount) => {
                  setAvailability({
                    shared: { amount: amount, unit: "qty" },
                    allocations: availability.allocations,
                  });
                }}
                onExclusiveChange={(subject, amount) => {
                  const allocations =
                    availability.allocations?.filter(
                      (allocation) => allocation.subject !== subject
                    ) || [];
                  if (amount) {
                    allocations.push({
                      subject: subject,
                      assets: { amount: amount, unit: "qty" },
                    });
                    setAvailability({
                      shared: availability.shared,
                      allocations: allocations,
                    });
                  } else {
                    setAvailability({
                      shared: availability.shared,
                      allocations: allocations,
                    });
                  }
                }}
              />
            </div>
            <div className={styles.component} style={{ marginTop: "1vw" }}>
              <label className={styles.label}>PRICING</label>
              <PricingView
                pricing={pricing}
                onDefaultChange={(amount, currency) => {
                  setPricing({
                    default: { amount: amount, currency: currency },
                    exclusives: pricing.exclusives,
                  });
                }}
                onExclusiveChange={(subject, amount, currency) => {
                  const exclusives =
                    pricing.exclusives?.filter(
                      (exclusive) => exclusive.subject !== subject
                    ) || [];
                  if (amount && currency) {
                    exclusives.push({
                      subject: subject,
                      price: { amount: amount, currency: currency },
                    });
                    setPricing({
                      default: pricing.default,
                      exclusives: exclusives,
                    });
                  } else {
                    setPricing({
                      default: pricing.default,
                      exclusives: exclusives,
                    });
                  }
                }}
              />
            </div>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

export default ProductContentView;
