import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductContentView from "../component/ProductContentView";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import {
  createExclusivePricing,
  createReservedAvailability,
  fetchResource,
  removeExclusivePricing,
  removeReservedAvailability,
  updateDefaultPricing,
  updateExclusivePricing,
  updateProduct,
  updateReservedAvailability,
  updateSharedAvailability,
} from "../client/AxiosClient";
import {
  closeProgress,
  showProgress,
} from "../../common/dialog/ProgressMessageIndicator";
import { Path } from "../utils/PathUtils";

const isDefined = (element) => {
  return element !== undefined;
};

const UpdateProductPage = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [actual, setActual] = useState();

  useEffect(() => {
    showLoading();
    fetchResource(uuid).then(setActual).finally(hideLoading);
  }, [uuid]);

  async function executeProductUpdate(uuid, product) {
    // 1 prepare general attributes
    showProgress(5, "Preparing attributes");
    const attributes = {
      name: product.name,
      description: product.description,
      image: product.image,
      startRetailDate: product.startRetailDate,
      endRetailDate: product.endRetailDate,
    };
    // 2 update the attributes
    showProgress(25, "Updating attributes");
    await updateProduct(uuid, attributes);
    // 3 prepare availabilities to create/update
    showProgress(30, "Preparing availabilities");
    const actualAllocations = actual.availability.allocations || [];
    const productAllocations = product.availability.allocations || [];
    // 3.1 prepare reserved availabilities for create, update and delete
    const createAllocations = [];
    const updateAllocations = [];
    const removeAllocations = [];
    productAllocations.forEach((allocation) => {
      const actualAllocation = actualAllocations.find(
        (it) => it.subject === allocation.subject
      );
      if (actualAllocation) {
        if (
          actualAllocation.assets.amount !== Number(allocation.assets.amount)
        ) {
          updateAllocations.push(allocation);
        }
      } else {
        createAllocations.push(allocation);
      }
    });
    actualAllocations.forEach((allocations) => {
      if (
        !productAllocations.some((it) => it.subject === allocations.subject)
      ) {
        removeAllocations.push(allocations);
      }
    });
    // 3.2 update shared availability
    if (
      actual.availability.shared.amount !==
      Number(product.availability.shared.amount)
    ) {
      showProgress(35, "Updating shared availability");
      await updateSharedAvailability(uuid, product.availability.shared);
    }
    // 3.3 create reserved availability
    if (createAllocations.length > 0) {
      showProgress(45, "Creating allocated availability");
      for (let i = 0; i < createAllocations.length; i++) {
        const allocation = createAllocations[i];
        await createReservedAvailability(
          uuid,
          allocation.subject,
          allocation.assets
        );
      }
    }
    // 3.4 update reserved availability
    if (updateAllocations.length > 0) {
      showProgress(55, "Updating allocated availability");
      for (let i = 0; i < updateAllocations.length; i++) {
        const allocation = updateAllocations[i];
        await updateReservedAvailability(
          uuid,
          allocation.subject,
          allocation.assets
        );
      }
    }
    // 3.5 remove reserved availability
    if (removeAllocations.length > 0) {
      showProgress(55, "Removing allocated availability");
      for (let i = 0; i < removeAllocations.length; i++) {
        const allocation = removeAllocations[i];
        await removeReservedAvailability(uuid, allocation.subject);
      }
    }
    showProgress(60, "Preparing pricing");
    const actualExclusives = actual.pricing.exclusives || [];
    const productExclusives = product.pricing.exclusives || [];
    // 4 prepare exclusive pricings for create, update and delete
    const createExclusives = [];
    const updateExclusives = [];
    const removeExclusives = [];
    productExclusives.forEach((exclusive) => {
      const actualExclusive = actualExclusives.find(
        (it) => it.subject === exclusive.subject
      );
      if (actualExclusive) {
        if (
          actualExclusive.price.amount !== Number(exclusive.price.amount) ||
          actualExclusive.price.currency !== exclusive.price.currency
        ) {
          updateExclusives.push(exclusive);
        }
      } else {
        createExclusives.push(exclusive);
      }
    });
    actualExclusives.forEach((exclusive) => {
      if (!productExclusives.some((it) => it.subject === exclusive.subject)) {
        removeExclusives.push(exclusive);
      }
    });
    // 4.1 update default pricing
    if (
      actual.pricing.default.amount !==
        Number(product.pricing.default.amount) ||
      actual.pricing.default.currency !== product.pricing.default.currency
    ) {
      showProgress(65, "Updating default pricing");
      await updateDefaultPricing(uuid, product.pricing.default);
    }
    // 4.2 create exclusive pricings
    if (createExclusives.length > 0) {
      showProgress(75, "Creating exclusive pricings");
      for (let i = 0; i < createExclusives.length; i++) {
        const exclusives = createExclusives[i];
        await createExclusivePricing(
          uuid,
          exclusives.subject,
          exclusives.price
        );
      }
    }
    // 4.3 update exclusive pricings
    if (updateExclusives.length > 0) {
      showProgress(85, "Updating exclusive pricings");
      for (let i = 0; i < updateExclusives.length; i++) {
        const exclusive = updateExclusives[i];
        await updateExclusivePricing(uuid, exclusive.subject, exclusive.price);
      }
    }
    // 4.4 remove exclusive pricings
    if (removeExclusives.length > 0) {
      showProgress(90, "Removing exclusive pricings");
      for (let i = 0; i < removeExclusives.length; i++) {
        const exclusive = removeExclusives[i];
        await removeExclusivePricing(uuid, exclusive.subject);
      }
    }
  }

  if (actual) {
    return (
      <ProductContentView
        element={actual}
        onClick={(product) => {
          if (
            isDefined(product.name) &&
            isDefined(product.availability?.shared) &&
            isDefined(product.pricing?.default)
          ) {
            executeProductUpdate(uuid, product)
              .catch(() =>
                showDialog(
                  "Error",
                  `An error occurred while updating, some changes 
                  may be saved. Please try again or contact us if the issue persists.`,
                  DialogScope.ERROR
                )
              )
              .finally(() => {
                closeProgress();
                navigate(Path.RESOURCE);
              });
          } else {
            showDialog(
              "Warning",
              `Some required details for the product are incomplete. Please review
                     and ensure name, shared availability and default pricing fields are complete.`,
              DialogScope.ERROR
            );
          }
        }}
      />
    );
  }
};

export default UpdateProductPage;
