const Path = Object.freeze({
  BASE: "/resource",
  RESOURCE: "/resource",
  RESOURCE_PROFILE: "profile",
  CREATE_PRODUCT: "product/create",
  UPDATE_PRODUCT: "product/update/:uuid",
  CREATE_GROUP: "group/create",
  UPDATE_GROUP: "group/update/:uuid",
});

export { Path };
