import { useNavigate } from "react-router-dom";
import ProductContentView from "../component/ProductContentView";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { createProduct } from "../client/AxiosClient";
import { Path } from "../utils/PathUtils";

const CreateProductPage = () => {
  const navigate = useNavigate();

  const isDefined = (element) => {
    return element !== undefined;
  };

  return (
    <ProductContentView
      onClick={(product) => {
        if (
          isDefined(product.name) &&
          isDefined(product.availability?.shared) &&
          isDefined(product.pricing?.default)
        ) {
          showLoading();
          createProduct(product)
            .then(() => navigate(Path.RESOURCE))
            .finally(hideLoading);
        } else {
          showDialog(
            "Warning",
            `Some required details for the product are incomplete. Please review
                     and ensure name, shared availability and default pricing fields are complete.`,
            DialogScope.ERROR
          );
        }
      }}
    />
  );
};

export default CreateProductPage;
