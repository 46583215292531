import GroupContentView from "../component/GroupContentView";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { createGroup } from "../client/AxiosClient";
import { Path } from "../utils/PathUtils";
import {
  DialogScope,
  showDialog,
} from "../../common/dialog/GenericScopeDialog";
import { useNavigate } from "react-router-dom";

const CreateGroupPage = () => {
  const navigate = useNavigate();

  return (
    <GroupContentView
      onClick={(group) => {
        if (group.name === undefined || group.items?.length === 0) {
          showDialog(
            "Warning",
            `Some required details for the group are incomplete.
                   Please review and ensure name field and entities are complete.`,
            DialogScope.ERROR
          );
        } else {
          showLoading();
          createGroup(group)
            .then(() => navigate(Path.RESOURCE))
            .finally(hideLoading);
        }
      }}
    />
  );
};

export default CreateGroupPage;
