import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import SearchView from "../../navigation/component/SearchView";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import {
  fetchChecklists,
  updateQuantity,
  removeItem,
  removeChecklist,
} from "../client/AxiosClient";
import styles from "../style/checklist.module.css";

const itemStyle = {
  display: "flex",
  alignItems: "center",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditQuantityButton = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [quantity, setQuantity] = useState(props.item.quantity);

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <div>
      <IconButton size="small" onClick={() => setShowDialog(true)}>
        <EditOutlinedIcon htmlColor="#0D6986" size="small" />
      </IconButton>
      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Update requested quantity</DialogTitle>
        <DialogContent>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "1vw" }}>Quantity: </label>
            <TextField
              id="quantity"
              name="quantity"
              defaultValue={quantity}
              variant="outlined"
              size="small"
              sx={{ flex: 1 }}
              slotProps={{
                htmlInput: {
                  type: "number",
                },
              }}
              onChange={(event) => {
                setQuantity(event.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abort</Button>
          <Button
            onClick={() => {
              handleClose();
              showLoading();
              updateQuantity(
                props.checklist.uuid,
                props.item.uuid,
                quantity
              ).finally(hideLoading);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const RemoveItemButton = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <div>
      <IconButton size="small" onClick={() => setShowDialog(true)}>
        <DeleteForeverOutlinedIcon htmlColor="#0D6986" size="small" />
      </IconButton>
      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to exclude the item "{props.item.name}" from
            checklist "{props.checklist.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
              showLoading();
              removeItem(props.checklist.uuid, props.item.uuid).finally(
                hideLoading
              );
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const RemoveChecklistButton = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <div>
      <IconButton size="small" onClick={() => setShowDialog(true)}>
        <DeleteForeverOutlinedIcon htmlColor="#0D6986" size="small" />
      </IconButton>
      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to remove the checklist "
            {props.checklist.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
              showLoading();
              removeChecklist(props.checklist.uuid).finally(hideLoading);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ChecklistPage = () => {
  const [checklists, setChecklists] = useState([]);

  useEffect(() => {
    showLoading();
    fetchChecklists().then(setChecklists).finally(hideLoading);
  }, []);

  const renderSymbol = (symbol) => {
    if (symbol === "EUR") {
      return "€";
    } else if (symbol === "USD") {
      return "$";
    } else {
      return <label className={styles.currencyLabel}>{symbol}</label>;
    }
  };

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.label}>Resources</label>
        </div>
        <div className={styles.content}>
          <div className={styles.control}>
            <div style={{ width: "20vw" }}>
              <SearchView onSearch={(phrase) => {}} onSearchCancel={() => {}} />
            </div>
          </div>
          <div className={styles.scrollable}>
            <Grid container spacing={1} sx={{ flex: 1 }}>
              {checklists.map((checklist) => {
                // console.log(checklist);
                return [
                  <Grid size={4} sx={itemStyle}>
                    <label>{checklist.name}</label>
                  </Grid>,
                  <Grid size={7}>
                    <Grid
                      container
                      spacing={1}
                      columns={10}
                      sx={{
                        flex: 1,
                        border: "1px solid #bbbbbb",
                        padding: "1vw",
                        borderRadius: 1,
                      }}
                    >
                      {checklist.items?.map((item) => {
                        return [
                          <Grid size={5} sx={itemStyle}>
                            <label>{item.name}</label>
                          </Grid>,
                          <Grid size={2} sx={itemStyle}>
                            <label>quantity: {item.quantity || 0}</label>
                          </Grid>,
                          <Grid size={2} sx={itemStyle}>
                            <label>
                              price: {renderSymbol(item.price?.currency)}
                              {item.price?.amount}
                            </label>
                          </Grid>,
                          <Grid size={1} sx={{ display: "flex" }}>
                            <EditQuantityButton
                              checklist={checklist}
                              item={item}
                            />
                            <RemoveItemButton
                              checklist={checklist}
                              item={item}
                            />
                          </Grid>,
                        ];
                      })}
                    </Grid>
                  </Grid>,
                  <Grid
                    size={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <RemoveChecklistButton checklist={checklist} />
                  </Grid>,
                  <Grid
                    size={12}
                    sx={{ height: "2px", backgroundColor: "#bbbbbb" }}
                  />,
                ];
              })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistPage;
