import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import Grid from "@mui/material/Grid2";
import ChromaView from "./ChromaView";
import styles from "../style/dialog.profile.module.css";
import { showChecklistDialog } from "../dialog/ChecklistDialog";

const contentStyle = {
  content: {
    top: "5vh",
    bottom: "5vh",
    left: "5vw",
    right: "5vw",
    display: "flex",
    padding: "2vw",
  },
  overlay: {
    zIndex: 1,
  },
};

const closeStyle = {
  position: "absolute",
  right: "1vh",
  top: "1vh",
};

const ResourceOverviewDialog = (props) => {
  const {
    uuid,
    name,
    description,
    image,
    startRetailDate,
    endRetailDate,
    items,
  } = props.resource;
  const [visible, setVisible] = useState(false);
  const [cover, setCover] = useState(image?.cover);
  const [assets, setAssets] = useState(props.resource.assets);
  const [price, setPrice] = useState(props.resource.price);
  const images = [image?.cover, ...image?.images];
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selected) {
      setCover(selected?.image || cover);
      setAssets(selected.assets);
      setPrice(selected.price);
    }
  }, [selected]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{ backgroundColor: "#fff", padding: 4 }}
        onClick={() => setVisible(true)}
      >
        <ZoomOutMapIcon htmlColor="#0d6986" fontSize="small" />
      </IconButton>
      <Modal isOpen={visible} style={contentStyle} onRequestClose={onClose}>
        <IconButton style={closeStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Grid container sx={{ flex: 1 }}>
          <Grid size={5} className={styles.imageContent}>
            <div style={{}}>
              <ChromaView src={cover} defaultColor="#EEEEEE">
                <div className={styles.cover}>
                  <img
                    style={{ maxWidth: "30vw", maxHeight: "60vh" }}
                    src={cover}
                    alt=""
                  />
                </div>
              </ChromaView>
            </div>
            <div className={styles.scrollableImages}>
              {images.map((src) => {
                return (
                  <NavLink
                    to={"#"}
                    onClick={() => setCover(src)}
                    style={{ paddingRight: "1vw", flex: 1 }}
                  >
                    <ChromaView src={cover} defaultColor="#EEEEEE">
                      <div
                        className={styles.image}
                        style={{
                          border: src === cover ? "1px solid black" : "none",
                        }}
                      >
                        <img
                          style={{ maxWidth: "7vw", maxHeight: "7vw" }}
                          src={src}
                          alt=""
                        />
                      </div>
                    </ChromaView>
                  </NavLink>
                );
              })}
            </div>
          </Grid>
          <Grid className={styles.detailsContent}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <label className={styles.name}>{name.default}</label>
              <label className={styles.itemName}>
                {selected?.name?.default && `/${selected?.name?.default}`}
              </label>
            </div>
            <label className={styles.description}>{description.default}</label>
            <div className={styles.inlineComponent}>
              <label className={styles.retailPeriod}>
                {startRetailDate && `Retails starts on ${startRetailDate}`}
              </label>
              <label className={styles.retailPeriod}>
                {endRetailDate && `Retails ends on ${endRetailDate}`}
              </label>
            </div>
            <div className={styles.inlineComponent}>
              <label className={styles.showcase}>
                {assets && `Available ${assets?.amount} items`}
              </label>
              <label className={styles.showcase}>
                {price && ` Price ${price?.currency}${price?.amount} per item`}
              </label>
            </div>
            <div className={styles.scrollableItems}>
              {items?.map((item) => {
                if (item.image) {
                  return (
                    <NavLink
                      to={"#"}
                      style={{ paddingRight: "1vw" }}
                      onClick={() => setSelected(item)}
                    >
                      <ChromaView src={item.image} defaultColor="#EEEEEE">
                        <img
                          className={styles.imageItem}
                          style={{
                            border:
                              selected?.uuid === item.uuid
                                ? "1px solid black"
                                : "none",
                          }}
                          src={item.image}
                          alt=""
                        />
                      </ChromaView>
                    </NavLink>
                  );
                } else if (item.name) {
                  return (
                    <NavLink
                      to={"#"}
                      onClick={() => setSelected(item)}
                      className={styles.textItem}
                      style={{
                        border:
                          selected?.uuid === item.uuid
                            ? "1px solid black"
                            : "none",
                      }}
                    >
                      {item.name.default}
                    </NavLink>
                  );
                }
              })}
            </div>
            <NavLink
              className={styles.button}
              to={"#"}
              onClick={() => {
                if (price) {
                  if (items && selected) {
                    showChecklistDialog(selected.uuid, selected.name);
                  } else {
                    showChecklistDialog(uuid, name);
                  }
                }
              }}
            >
              order
            </NavLink>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ResourceOverviewDialog;
