import { useState } from "react";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import Modal from "react-modal";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";

const slice = createSlice({
  name: "progressMessageState",
  initialState: {
    progress: undefined,
    message: undefined,
  },
  reducers: {
    showProgress(state, action) {
      const { progress, message } = action.payload;
      state.progress = progress;
      state.message = message;
    },
    closeProgress(state) {
      state.progress = undefined;
      state.message = undefined;
    },
  },
});

const store = configureStore({
  reducer: {
    progressMessageState: slice.reducer,
  },
});

const showProgress = (progress, message) => {
  store.dispatch(slice.actions.showProgress({ progress, message }));
};

const closeProgress = () => {
  store.dispatch(slice.actions.closeProgress());
};

const contentStyle = {
  content: {
    top: "30vh",
    bottom: "30vh",
    left: "25vw",
    right: "25vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    zIndex: 10,
  },
};

const ProgressMessageIndicator = () => {
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  store.subscribe(() => {
    const { progress, message } = store.getState().progressMessageState;
    setProgress(progress);
    setMessage(message);
    setVisible(progress && message);
  });

  return (
    <Modal isOpen={visible} style={contentStyle}>
      <LinearProgress
        determinate
        variant="outlined"
        color="neutral"
        size="sm"
        thickness={24}
        value={Number(progress)}
        sx={{
          "--LinearProgress-radius": "20px",
          "--LinearProgress-thickness": "24px",
        }}
      >
        <Typography
          level="body-xs"
          textColor="common.white"
          sx={{ fontWeight: "xl", mixBlendMode: "difference" }}
        >
          {message} ... {`${Math.round(Number(progress))}%`}
        </Typography>
      </LinearProgress>
    </Modal>
  );
};

export { showProgress, closeProgress };

export default ProgressMessageIndicator;
