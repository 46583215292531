import axiosClient, {
  executeGetJsonStreamRequest,
} from "../../common/client/AxiosClient";
import { authorisationToken } from "../../common/client/AuthClient";

export const fetchResources = () => {
  return executeGetJsonStreamRequest("/resource/summary");
};

export const fetchResource = (uuid) => {
  return authorisationToken().then((token) => {
    return axiosClient
      .get("/resource?uuid=" + uuid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          try {
            resolve(response.data);
          } catch (error) {
            reject(error);
          }
        });
      });
  });
};

export const searchResources = () => {
  return executeGetJsonStreamRequest("/resource/search");
};

export const createProduct = (product) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/product", product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateProduct = (uuid, product) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/resource/product/" + uuid, product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const removeProduct = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/resource/product/" + uuid, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const createGroup = (group) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/group", group, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateGroup = (uuid, group) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/resource/group/" + uuid, group, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const removeGroup = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/resource/group/" + uuid, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const createGroupItem = (uuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/group/" + uuid + "/item", item, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateGroupItem = (uuid, itemUuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/resource/group/" + uuid + "/item/" + itemUuid, item, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const removeGroupItem = (uuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/resource/group/" + uuid + "/item/" + item, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateSharedAvailability = (uuid, assets) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/resource/availability/" + uuid, assets, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const createReservedAvailability = (uuid, subject, assets) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/availability/" + uuid + "/" + subject, assets, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateReservedAvailability = (uuid, subject, assets) => {
  return authorisationToken().then((token) =>
    axiosClient.patch(
      "/resource/availability/" + uuid + "/" + subject,
      assets,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  );
};

export const removeReservedAvailability = (uuid, subject) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/resource/availability/" + uuid + "/" + subject, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateDefaultPricing = (uuid, price) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/resource/pricing/" + uuid, price, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const createExclusivePricing = (uuid, subject, price) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/pricing/" + uuid + "/" + subject, price, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const updateExclusivePricing = (uuid, subject, price) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/resource/pricing/" + uuid + "/" + subject, price, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const removeExclusivePricing = (uuid, subject) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/resource/pricing/" + uuid + "/" + subject, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};
