import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const ClearableTextField = (props) => {
  const [value, setValue] = useState(props.value);

  return (
    <TextField
      id={props.id}
      value={value}
      label={props.label}
      variant="outlined"
      size="small"
      sx={{ width: "28vw", marginTop: "2vh" }}
      slotProps={
        value && {
          input: {
            rows: 3,
            multiline: props.multiline || false,
            endAdornment: (
              <IconButton
                size="small"
                onClick={() => {
                  setValue("");
                  props.onChange("");
                }}
              >
                <ClearIcon size="small" />
              </IconButton>
            ),
          },
        }
      }
      onChange={(event) => {
        const { value } = event.target;
        setValue(value);
        props.onChange(value);
      }}
    />
  );
};

export default ClearableTextField;
