import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SearchView from "../../navigation/component/SearchView";
import { v4 as uuidv4 } from "uuid";
import { fetchActive } from "../../common/client/PartnershipClient";
import styles from "../style/showcase.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CardView = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Grid size={4} className={styles.card} key={uuidv4()}>
      {props.amount && (
        <IconButton
          sx={{
            width: "2.2vw",
            height: "2.2vw",
          }}
          style={{
            position: "absolute",
            right: 5,
            top: 5,
            backgroundColor: "#eeeeee",
          }}
          onClick={props.onRemove}
        >
          <DeleteOutlineOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
      )}
      <div className={styles.cardContent}>{props.children}</div>
      <NavLink
        to={"#"}
        style={{ textDecoration: "none" }}
        onClick={() => setShowDialog(true)}
        className={`${styles.cardControl} ${
          props.amount ? styles.presence : styles.absence
        }`}
      >
        {props.amount || "not defined"}
        <IconButton fontSize="small">
          <ModeEditOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
      </NavLink>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const amount = formJson.assetsAmount;
            handleClose();
            if (Number(amount) !== props.amount) {
              props.onChange(amount);
            }
          },
        }}
      >
        <DialogTitle>Update availability</DialogTitle>
        <DialogContent>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "1vw" }}>Assets amount:</label>
            <TextField
              id="assetsAmount"
              name="assetsAmount"
              defaultValue={props.amount}
              variant="outlined"
              size="small"
              sx={{ flex: 1 }}
              slotProps={{
                htmlInput: {
                  type: "number",
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const AvailabilityView = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [phrase, setPhrase] = useState();
  const shared = props.availability?.shared || {};
  const allocations = props.availability?.allocations || [];

  useEffect(() => {
    fetchActive().then((contacts) => {
      setAccounts(contacts.map((contact) => contact.account));
    });
  }, []);

  return (
    <div>
      <div style={{ marginTop: "1vh" }} className={styles.control}>
        <div style={{ width: "20vw" }}>
          <SearchView onSearch={setPhrase} onSearchCancel={() => {}} />
        </div>
      </div>
      <div className={styles.content} key={"availability-view"}>
        <Grid container spacing={3} sx={{ flex: 1 }}>
          <CardView
            amount={shared.amount}
            onRemove={() => props.onSharedChange(undefined)}
            onChange={props.onSharedChange}
          >
            <div className={styles.element}>
              <label className={styles.label}>Shared</label>
            </div>
          </CardView>
          {accounts.map((account) => {
            const assets = allocations.find(
              (allocation) => allocation.subject === account.uuid
            )?.assets;
            return (
              <CardView
                amount={assets?.amount}
                onRemove={() =>
                  props.onExclusiveChange(account.uuid, undefined)
                }
                onChange={(amount) =>
                  props.onExclusiveChange(account.uuid, amount)
                }
              >
                <div className={styles.element}>
                  <img
                    src={account.logo}
                    style={{ maxWidth: "6vw", maxHeight: "6vh" }}
                    alt=""
                  />
                  <label className={styles.label} style={{ marginTop: "2vh" }}>
                    {account.name}
                  </label>
                </div>
              </CardView>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default AvailabilityView;
