import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SearchView from "../../navigation/component/SearchView";
import { fetchActive } from "../../common/client/PartnershipClient";
import styles from "../style/showcase.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Menu,
  MenuItem,
  Slide,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CardView = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(props.amount);
  const [currency, setCurrency] = useState(props.currency);

  const handleSpeechClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSpeechClose = () => {
    setAnchorEl(null);
  };

  const currencySymbol = () => {
    let symbol = currency;
    if (currency === "EUR") {
      symbol = "€";
    } else if (currency === "USD") {
      symbol = "$";
    }
    return <div style={{ marginRight: 5 }}>{symbol}</div>;
  };

  const handleClose = () => {
    setShowDialog(false);
    setValue(props.amount);
    setCurrency(props.currency);
  };

  const renderSymbol = (symbol) => {
    if (symbol === "EUR") {
      return "€";
    } else if (symbol === "USD") {
      return "$";
    } else {
      return <label className={styles.currencyLabel}>{symbol}</label>;
    }
  };

  return (
    <Grid size={4} className={styles.card}>
      {props.amount && (
        <IconButton
          sx={{
            width: "2.2vw",
            height: "2.2vw",
          }}
          style={{
            position: "absolute",
            right: 5,
            top: 5,
            backgroundColor: "#eeeeee",
          }}
          onClick={props.onRemove}
        >
          <DeleteOutlineOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
      )}
      <div className={styles.cardContent}>{props.children}</div>
      <NavLink
        to={"#"}
        style={{ textDecoration: "none" }}
        onClick={() => setShowDialog(true)}
        className={`${styles.cardControl} ${
          props.amount ? styles.presence : styles.absence
        }`}
      >
        <div>
          {props.currency && renderSymbol(props.currency)}
          {props.amount || "not defined"}
        </div>
        <IconButton fontSize="small">
          <ModeEditOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
      </NavLink>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Update pricing</DialogTitle>
        <DialogContent>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "1vw" }}>Pricing:</label>
            <TextField
              id="pricingAmount"
              name="pricingAmount"
              defaultValue={props.amount}
              variant="outlined"
              size="small"
              disabled={!currency}
              sx={{ flex: 1 }}
              slotProps={{
                htmlInput: { type: "number" },
                input: {
                  startAdornment: currency && currencySymbol(),
                  endAdornment: (
                    <div style={{ marginRight: "-1vw" }}>
                      <IconButton size="small" onClick={handleSpeechClick}>
                        <SellOutlinedIcon size="small" />
                      </IconButton>
                      <Menu
                        id="currencyFadeMenu"
                        name="currencyFadeMenu"
                        MenuListProps={{ "aria-labelledby": "fade-button" }}
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={handleSpeechClose}
                        TransitionComponent={Fade}
                      >
                        <MenuItem
                          onClick={() => {
                            handleSpeechClose();
                            setCurrency("EUR");
                          }}
                        >
                          EUR
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleSpeechClose();
                            setCurrency("USD");
                          }}
                        >
                          USD
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleSpeechClose();
                            setCurrency("RON");
                          }}
                        >
                          RON
                        </MenuItem>
                      </Menu>
                    </div>
                  ),
                },
              }}
              onChange={(event) => {
                setValue(event.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (
                currency !== props.currency ||
                Number(value) !== props.amount
              ) {
                props.onChange(value, currency);
              }
              setShowDialog(false);
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const PricingView = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [phrase, setPhrase] = useState();

  const defaultPrice = props.pricing?.default || {};
  const exclusives = props.pricing?.exclusives || [];

  useEffect(() => {
    fetchActive().then((contacts) => {
      setAccounts(contacts.map((contact) => contact.account));
    });
  }, []);

  return (
    <div>
      <div style={{ marginTop: "1vh" }} className={styles.control}>
        <div style={{ width: "20vw" }}>
          <SearchView onSearch={setPhrase} onSearchCancel={() => {}} />
        </div>
      </div>
      <div className={styles.content}>
        <Grid container spacing={3} sx={{ flex: 1 }}>
          <CardView
            amount={defaultPrice.amount}
            currency={defaultPrice.currency}
            onChange={(amount, currency) => {
              props.onDefaultChange(amount, currency);
            }}
            onRemove={() => props.onDefaultChange(undefined, undefined)}
          >
            <div className={styles.element}>
              <label className={styles.label}>Default</label>
            </div>
          </CardView>
          {accounts.map((account) => {
            const price = exclusives.find(
              (pricing) => pricing.subject === account.uuid
            )?.price;
            return (
              <CardView
                amount={price?.amount}
                currency={price?.currency}
                onChange={(amount, currency) => {
                  props.onExclusiveChange(account.uuid, amount, currency);
                }}
                onRemove={() => {
                  props.onExclusiveChange(account.uuid, undefined, undefined);
                }}
              >
                <div className={styles.element}>
                  <img
                    src={account.logo}
                    style={{ maxWidth: "6vw", maxHeight: "6vh" }}
                    alt=""
                  />
                  <label className={styles.label} style={{ marginTop: "2vh" }}>
                    {account.name}
                  </label>
                </div>
              </CardView>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default PricingView;
