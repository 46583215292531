import axiosClient, {
  executeGetJsonStreamRequest,
} from "../../common/client/AxiosClient";
import { authorisationToken } from "../../common/client/AuthClient";

export const fetchChecklists = () => {
  return executeGetJsonStreamRequest("/checklist");
};

export const updateQuantity = (uuid, item, quantity) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/checklist/" + uuid + "/" + item, quantity, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};

export const removeItem = (uuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/checklist/" + uuid + "/" + item, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};

export const removeChecklist = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/checklist/" + uuid, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};
