import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import ChromaView from "./ChromaView";
import IconButton from "@mui/material/IconButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import styles from "../style/summary.module.css";
import { useNavigate } from "react-router-dom";
import { removeGroup, removeProduct } from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { Path } from "../utils/PathUtils";

const controlButton = { backgroundColor: "#fff", padding: 4 };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ControlView = (props) => {
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  if (show) {
    return (
      <div className={styles.controls} onMouseLeave={() => setShow(false)}>
        <IconButton size="small" style={controlButton}>
          <ZoomOutMapIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          style={controlButton}
          onClick={() => {
            const path = props.aggreagtion
              ? "group/update/"
              : "product/update/";
            navigate(path + props.uuid);
          }}
        >
          <ModeEditOutlineOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          style={controlButton}
          onClick={() => setShowDialog(true)}
        >
          <DeleteOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>

        <Dialog
          open={showDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowDialog(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to remove this selection? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setShowDialog(false);
                showLoading();
                // TODO: define callback function to remove the actual from the state
                if (props.aggreagtion) {
                  removeGroup(props.uuid)
                    .then(() => navigate(Path.RESOURCE))
                    .finally(hideLoading);
                } else {
                  removeProduct(props.uuid)
                    .then(() => navigate(Path.RESOURCE))
                    .finally(hideLoading);
                }
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <IconButton
        size="small"
        style={{
          position: "absolute",
          right: 3,
          top: 3,
          backgroundColor: "#fff",
          padding: 3,
        }}
        onClick={() => setShow(true)}
      >
        <MoreVertIcon htmlColor="#0d6986" fontSize="small" />
      </IconButton>
    );
  }
};

const SummaryCardView = (props) => {
  return (
    <Grid size={2}>
      <ChromaView src={props.image}>
        <div className={styles.image}>
          <img
            style={{ maxWidth: "13vw", maxHeight: "30vh" }}
            src={props.image}
            alt=""
          />
          <Tooltip
            arrow
            title={
              props.aggreagtion ? "group of products" : "standalone product"
            }
            placement="right"
            size="small"
            className={styles.tooltip}
          >
            <CategoryOutlinedIcon htmlColor="#0d6986" fontSize="small" />
          </Tooltip>
          <ControlView uuid={props.uuid} aggreagtion={props.aggreagtion} />
        </div>
        <div className={styles.message}>
          <label className={styles.name}>
            {props.name?.length > 25
              ? `${props.name?.substring(0, 20)}...`
              : props.name}
          </label>
          <label className={styles.description}>
            {props.description?.substring(0, 95)}{" "}
            {props.description?.length >= 95 && "..."}
          </label>
        </div>
      </ChromaView>
    </Grid>
  );
};

export default SummaryCardView;
