import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GroupContentView from "../component/GroupContentView";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import {
  createExclusivePricing,
  createGroupItem,
  createReservedAvailability,
  fetchResource,
  removeExclusivePricing,
  removeGroupItem,
  removeReservedAvailability,
  updateDefaultPricing,
  updateExclusivePricing,
  updateGroup,
  updateGroupItem,
  updateReservedAvailability,
  updateSharedAvailability,
} from "../client/AxiosClient";
import { Path } from "../utils/PathUtils";
import {
  closeProgress,
  showProgress,
} from "../../common/dialog/ProgressMessageIndicator";

const UpdateGroupPage = () => {
  const { uuid } = useParams();
  const [actual, setActual] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    showLoading();
    fetchResource(uuid).then(setActual).finally(hideLoading);
  }, [uuid]);

  async function executeGroupUpdate(uuid, group) {
    // 1 prepare general attributes
    showProgress(5, "Preparing attributes");
    const attributes = {
      name: group.name,
      description: group.description,
      image: group.image,
      startRetailDate: group.startRetailDate,
      endRetailDate: group.endRetailDate,
    };
    // 2 update the attributes
    showProgress(25, "Updating attributes");
    await updateGroup(uuid, attributes);
    // 3 preparing item updates
    showProgress(30, "Preparing item updates");
    const createItems = [];
    const updateItems = [];
    const removeItems = actual.items.filter(
      (item) => !group.items.some((it) => it.uuid === item.uuid)
    );
    group.items.forEach((item) => {
      if (actual.items.some((it) => it.uuid === item.uuid)) {
        updateItems.push(item);
      } else {
        createItems.push(item);
      }
    });
    // 3.1 create group items
    showProgress(35, "Adding new items to group");
    for (let i = 0; i < createItems.length; i++) {
      const item = createItems[i];
      item.uuid = undefined;
      await createGroupItem(uuid, item);
    }
    // 3.2 remove group items
    showProgress(45, "Removing items group");
    for (let i = 0; i < removeItems.length; i++) {
      const item = removeItems[i];
      await removeGroupItem(uuid, item.uuid);
    }
    // 4 update group items
    showProgress(55, "Updating items' attributes");
    for (let i = 0; i < updateItems.length; i++) {
      const item = updateItems[i];
      await updateGroupItem(uuid, item.uuid, {
        name: item.name,
        description: item.description,
        image: item.image,
      });
    }
    // 4 updating group items' availability
    showProgress(55, "Preparing item availabilities");
    for (let i = 0; i < updateItems.length; i++) {
      const item = updateItems[i];
      const actualItem = actual.items.find((it) => it.uuid === item.uuid);

      const actualAllocations = actualItem.availability.allocations || [];
      const itemAllocations = item.availability.allocations || [];
      const createAllocations = [];
      const updateAllocations = [];
      const removeAllocations = [];
      itemAllocations.forEach((allocation) => {
        const actualAllocation = actualAllocations.find(
          (it) => it.subject === allocation.subject
        );
        if (actualAllocation) {
          if (
            actualAllocation.assets.amount !== Number(allocation.assets.amount)
          ) {
            updateAllocations.push(allocation);
          }
        } else {
          createAllocations.push(allocation);
        }
      });
      actualAllocations.forEach((allocations) => {
        if (!itemAllocations.some((it) => it.subject === allocations.subject)) {
          removeAllocations.push(allocations);
        }
      });
      // update shared availability
      if (
        actualItem.availability.shared.amount !==
        Number(item.availability.shared.amount)
      ) {
        await updateSharedAvailability(item.uuid, item.availability.shared);
      }
      // create reserved availability
      if (createAllocations.length > 0) {
        for (let i = 0; i < createAllocations.length; i++) {
          const allocation = createAllocations[i];
          await createReservedAvailability(
            item.uuid,
            allocation.subject,
            allocation.assets
          );
        }
      }
      // update reserved availability
      if (updateAllocations.length > 0) {
        for (let i = 0; i < updateAllocations.length; i++) {
          const allocation = updateAllocations[i];
          await updateReservedAvailability(
            item.uuid,
            allocation.subject,
            allocation.assets
          );
        }
      }
      // remove reserved availability
      if (removeAllocations.length > 0) {
        for (let i = 0; i < removeAllocations.length; i++) {
          const allocation = removeAllocations[i];
          await removeReservedAvailability(item.uuid, allocation.subject);
        }
      }
      showProgress(85, "Preparing items pricings");
      const actualExclusives = actualItem.pricing.exclusives || [];
      const itemExclusives = item.pricing.exclusives || [];
      const createExclusives = [];
      const updateExclusives = [];
      const removeExclusives = [];
      itemExclusives.forEach((exclusive) => {
        const actualExclusive = actualExclusives.find(
          (it) => it.subject === exclusive.subject
        );
        if (actualExclusive) {
          if (
            actualExclusive.price.amount !== Number(exclusive.price.amount) ||
            actualExclusive.price.currency !== exclusive.price.currency
          ) {
            updateExclusives.push(exclusive);
          }
        } else {
          createExclusives.push(exclusive);
        }
      });
      actualExclusives.forEach((exclusive) => {
        if (!itemExclusives.some((it) => it.subject === exclusive.subject)) {
          removeExclusives.push(exclusive);
        }
      });
      // update default pricing
      if (
        actualItem.pricing.default.amount !==
          Number(item.pricing.default.amount) ||
        actualItem.pricing.default.currency !== item.pricing.default.currency
      ) {
        await updateDefaultPricing(item.uuid, item.pricing.default);
      }
      //create exclusive pricings
      if (createExclusives.length > 0) {
        for (let i = 0; i < createExclusives.length; i++) {
          const exclusives = createExclusives[i];
          await createExclusivePricing(
            item.uuid,
            exclusives.subject,
            exclusives.price
          );
        }
      }
      // update exclusive pricings
      if (updateExclusives.length > 0) {
        for (let i = 0; i < updateExclusives.length; i++) {
          const exclusive = updateExclusives[i];
          await updateExclusivePricing(
            item.uuid,
            exclusive.subject,
            exclusive.price
          );
        }
      }
      //remove exclusive pricings
      if (removeExclusives.length > 0) {
        for (let i = 0; i < removeExclusives.length; i++) {
          const exclusive = removeExclusives[i];
          await removeExclusivePricing(item.uuid, exclusive.subject);
        }
      }
    }
  }

  if (actual) {
    return (
      <GroupContentView
        element={actual}
        onClick={(group) => {
          if (group.name && group.items?.length > 0) {
            executeGroupUpdate(uuid, group)
              .catch((error) => {
                console.log(error);
                showDialog(
                  "Error",
                  `An error occurred while updating, some changes 
                  may be saved. Please try again or contact us if the issue persists.`,
                  DialogScope.ERROR
                );
              })
              .finally(() => {
                closeProgress();
                navigate(Path.RESOURCE);
              });
          } else {
            showDialog(
              "Warning",
              `Some required details for the group are incomplete.  Please review and ensure name field and entities are complete.`,
              DialogScope.ERROR
            );
          }
        }}
      />
    );
  }
};

export default UpdateGroupPage;
