import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import IconButton from "@mui/material/IconButton";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Tooltip } from "@mui/material";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import ManageItemDialog from "./ManageItemDialog";
import SearchView from "../../navigation/component/SearchView";
import generalStyles from "../style/resource.module.css";
import styles from "../style/item.module.css";

const calculateAllocations = (allocations) => {
  if (allocations) {
    let amount = 0;
    allocations
      .map((allocation) => Number(allocation.assets.amount))
      .forEach((allocation) => (amount = amount + allocation));
    return amount;
  } else {
    return "0";
  }
};

const MessageTooltip = (props) => {
  const containLocales = () => {
    return props.message?.bundles?.length > 0;
  };

  const renderTitle = () => {
    if (containLocales()) {
      return `This field has locale-specific translations. The appropriate 
        translation will be displayed based on the selected language or region.`;
    } else {
      return `This field does not have a locale-specific translation.
        A generic version will be displayed instead.`;
    }
  };

  return (
    <Tooltip
      sx={{ marginLeft: "5px" }}
      arrow
      title={renderTitle()}
      placement="right"
    >
      <GTranslateIcon
        htmlColor={containLocales() ? "#0D6986" : "#BBBBBB"}
        fontSize={"small"}
      />
    </Tooltip>
  );
};

const GroupItemDisplayView = (props) => {
  const [phrase, setPhrase] = useState();

  return (
    <div style={{ marginTop: "2vh" }}>
      <div className={generalStyles.control}>
        <div style={{ width: "20vw" }}>
          <SearchView
            onSearch={setPhrase}
            onSearchCancel={() => setPhrase(undefined)}
          />
        </div>
        <div style={{ display: "flex" }} />
      </div>
      <Grid
        sx={{ flex: 1, marginTop: "1vh" }}
        container
        columns={100}
        spacing={0.1}
      >
        <Grid item size={25} key={`header-cell-name`} className={styles.header}>
          Name
        </Grid>
        <Grid
          item
          size={30}
          key={`header-cell-description`}
          className={styles.header}
        >
          Description
        </Grid>
        <Grid
          item
          size={5}
          key={`header-cell-image`}
          className={styles.header}
        />
        <Grid
          item
          size={15}
          key={`header-cell-availability`}
          className={styles.header}
        >
          Availability
        </Grid>
        <Grid
          item
          size={15}
          key={`header-cell-pricing`}
          className={styles.header}
        >
          Pricing
        </Grid>
        <Grid
          item
          size={10}
          key={`header-cell-controls`}
          className={styles.header}
        />
        {props.items.map((item) => {
          const { name, description, image, availability, pricing } = item;
          return [
            <Grid item size={25} key={`item-cell-name`} className={styles.item}>
              <label
                style={{ color: name?.default ? "#0D6986" : "#BBBBBB" }}
                className={styles.message}
              >
                {name?.default || "no name"}
              </label>
              <MessageTooltip message={name} />
            </Grid>,

            <Grid
              item
              size={30}
              key={`item-cell-description`}
              className={styles.item}
            >
              <label
                style={{ color: description?.default ? "#0D6986" : "#BBBBBB" }}
                className={styles.message}
              >
                {description?.default || "no description"}
              </label>
              <MessageTooltip message={description} />
            </Grid>,

            <Grid
              item
              size={5}
              key="item-cell-image-indicator"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ImageOutlinedIcon
                htmlColor={image ? "#0D6986" : "#BBBBBB"}
                size="small"
              />
            </Grid>,
            <Grid
              item
              size={15}
              key="item-availability-pricing"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                paddingLeft: "5px",
              }}
            >
              <label
                style={{ color: availability?.shared ? "#0D6986" : "#BBBBBB" }}
                className={styles.hintLabel}
              >
                {`shared: ${availability?.shared?.amount || "0"}`}
              </label>
              <label
                style={{ color: availability?.shared ? "#0D6986" : "#BBBBBB" }}
                className={styles.hintLabel}
              >
                {`reserved: ${calculateAllocations(availability?.allocations)}`}
              </label>
            </Grid>,
            <Grid
              item
              size={15}
              key="item-pricing-pricing"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                paddingLeft: "5px",
              }}
            >
              <label
                style={{ color: availability?.shared ? "#0D6986" : "#BBBBBB" }}
                className={styles.hintLabel}
              >
                {pricing?.default?.amount
                  ? `default: ${pricing?.default?.amount} ${pricing?.default?.currency}`
                  : "0"}
              </label>
              <label
                style={{ color: pricing?.exclusives ? "#0D6986" : "#BBBBBB" }}
                className={styles.hintLabel}
              >
                exclusives: {pricing?.exclusives?.length || "0"}
              </label>
            </Grid>,
            <Grid
              item
              size={10}
              key="item-cell-controls"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <IconButton size="small" onClick={() => props.onRemove(item)}>
                <DeleteForeverOutlinedIcon htmlColor="#0D6986" size="small" />
              </IconButton>
              <ManageItemDialog item={item} onClick={props.onUpdate}>
                <IconButton size="small">
                  <EditOutlinedIcon htmlColor="#0D6986" size="small" />
                </IconButton>
              </ManageItemDialog>
            </Grid>,
            <Grid size={100} sx={{ margin: "1vh 0" }}>
              <div className={styles.separator} />
            </Grid>,
          ];
        })}
        <Grid
          item
          size={100}
          sx={{
            display: "flex",
            justifyContent: "end",
            margin: "2vh 0",
          }}
        >
          <ManageItemDialog onClick={props.onCreate}>
            <NavLink to={"#"} className={styles.button}>
              add entity
            </NavLink>
          </ManageItemDialog>
        </Grid>
      </Grid>
    </div>
  );
};

export default GroupItemDisplayView;
