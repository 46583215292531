import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProvider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AvailabilityView from "./AvailabilityView";
import PricingView from "./PricingView";
import ClearableTextField from "./ClearableTextField";
import ClearableSpeechLabel from "./ClearableSpeechLabel";
import SpeechTextField from "./SpeechTextField";
import CoverImagePicker from "./CoverImagePicker";
import {
  DialogScope,
  showDialog,
} from "../../common/dialog/GenericScopeDialog";
import { InputTheme } from "../../common/utils/ThemeUtils";
import { buildMessageBundle } from "../utils/Utils";
import styles from "../style/resource.module.css";

const contentStyle = {
  content: {
    top: "5vh",
    bottom: "5vh",
    left: "5vw",
    right: "5vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: "1vh",
    paddingBottom: "1vh",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    backgroundColor: "white",
  },
  overlay: {
    zIndex: 1,
  },
};

const closeStyle = {
  position: "absolute",
  right: "1vh",
  top: "1vh",
};

const ManageItemDialog = (props) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(props.item?.name?.default);
  const [nameLocales, setNameLocales] = useState(
    props.item?.name?.bundles || []
  );
  const [description, setDescription] = useState(
    props.item?.description?.default
  );
  const [descriptionLocales, setDescriptionLocales] = useState(
    props.item?.description?.bundles || []
  );
  const [cover, setCover] = useState(props.item?.image);
  const [availability, setAvailability] = useState(
    props.item?.availability || {}
  );
  const [pricing, setPricing] = useState(props.item?.pricing || {});

  useEffect(() => {
    setName(props.item?.name?.default);
    setNameLocales(props.item?.name?.bundles || []);
    setDescription(props.item?.description?.default);
    setDescriptionLocales(props.item?.description?.bundles || []);
    setCover(props.item?.image);
    setAvailability(props.item?.availability || {});
    setPricing(props.item?.pricing || {});
  }, [props.item]);

  const onClose = () => {
    setVisible(false);
    if (props.item === undefined) {
      setName(undefined);
      setNameLocales([]);
      setDescription(undefined);
      setDescriptionLocales([]);
      setCover(undefined);
      setAvailability({});
      setPricing({});
    }
  };

  if (visible) {
    return (
      <Modal isOpen={visible} style={contentStyle} onRequestClose={onClose}>
        <IconButton style={closeStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Grid
          container
          size={12}
          spacing={3}
          sx={{ overflowY: "scroll" }}
          className={styles.content}
        >
          <ThemeProvider theme={InputTheme}>
            <Grid sx={{ flex: 1 }} key={"general-column"}>
              <div className={styles.component}>
                <label className={styles.label}>GENERAL</label>
                {/*  item name section  */}
                <label className={styles.text}>Product Name</label>
                <ClearableTextField
                  id="default-name-input"
                  label="Default Entity Name"
                  value={name}
                  onChange={setName}
                />
                {nameLocales.map((locale) => {
                  return (
                    <ClearableSpeechLabel
                      speech={locale.speech}
                      text={locale.value}
                      onRemove={() =>
                        setNameLocales([
                          ...nameLocales.filter(
                            (it) => it.speech !== locale.speech
                          ),
                        ])
                      }
                    />
                  );
                })}
                <SpeechTextField
                  id="locale-name-input"
                  onChange={(value) => {
                    setNameLocales([
                      ...nameLocales.filter((it) => it.speech !== value.speech),
                      value,
                    ]);
                  }}
                />
                {/*  item description section  */}
                <label className={styles.text}>Product Description</label>
                <ClearableTextField
                  id="default-description-input"
                  label="Default Entity Description"
                  value={description}
                  multiline={true}
                  onChange={setDescription}
                />
                {descriptionLocales.map((locale) => {
                  return (
                    <ClearableSpeechLabel
                      speech={locale.speech}
                      text={locale.value}
                      onRemove={() =>
                        setDescriptionLocales([
                          ...descriptionLocales.filter(
                            (it) => it.speech !== locale.speech
                          ),
                        ])
                      }
                    />
                  );
                })}
                <SpeechTextField
                  id="locale-description-input"
                  multiline={true}
                  onChange={(value) => {
                    setDescriptionLocales([
                      ...descriptionLocales.filter(
                        (it) => it.speech !== value.speech
                      ),
                      value,
                    ]);
                  }}
                />
                {/*  item media section  */}
                <label className={styles.text}>Product Media</label>
                <div style={{ display: "flex", marginTop: "2vh" }}>
                  <CoverImagePicker
                    src={cover}
                    onSelect={setCover}
                    onRemove={() => setCover(null)}
                  />
                </div>
              </div>
            </Grid>
            <Grid size={6} key={"showcase-column"}>
              <div className={styles.component}>
                <label className={styles.label}>AVAILABILITY</label>
                <AvailabilityView
                  availability={availability}
                  onSharedChange={(amount) => {
                    setAvailability({
                      shared: { amount: amount, unit: "qty" },
                      allocations: availability.allocations,
                    });
                  }}
                  onExclusiveChange={(subject, amount) => {
                    const allocations =
                      availability.allocations?.filter(
                        (allocation) => allocation.subject !== subject
                      ) || [];
                    if (amount) {
                      allocations.push({
                        subject: subject,
                        assets: { amount: amount, unit: "qty" },
                      });
                      setAvailability({
                        shared: availability.shared,
                        allocations: allocations,
                      });
                    } else {
                      setAvailability({
                        shared: availability.shared,
                        allocations: allocations,
                      });
                    }
                  }}
                />
              </div>
              <div className={styles.component} style={{ marginTop: "1vw" }}>
                <label className={styles.label}>PRICING</label>
                <PricingView
                  pricing={pricing}
                  onDefaultChange={(amount, currency) => {
                    setPricing({
                      default: { amount: amount, currency: currency },
                      exclusives: pricing.exclusives,
                    });
                  }}
                  onExclusiveChange={(subject, amount, currency) => {
                    const exclusives =
                      pricing.exclusives?.filter(
                        (exclusive) => exclusive.subject !== subject
                      ) || [];
                    if (amount && currency) {
                      exclusives.push({
                        subject: subject,
                        price: { amount: amount, currency: currency },
                      });
                      setPricing({
                        default: pricing.default,
                        exclusives: exclusives,
                      });
                    } else {
                      setPricing({
                        default: pricing.default,
                        exclusives: exclusives,
                      });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              size={12}
              sx={{ display: "flex", justifyContent: "end" }}
              key={"showcase-column"}
            >
              <NavLink
                to={"#"}
                className={styles.button}
                onClick={() => {
                  if (
                    name === undefined ||
                    availability?.shared === undefined ||
                    pricing?.default === undefined
                  ) {
                    showDialog(
                      "Warning",
                      `Some required details for the entity are incomplete. Please review
                    and ensure name, shared availability and default pricing fields are complete.`,
                      DialogScope.ERROR
                    );
                  } else {
                    onClose();
                    props.onClick({
                      uuid: props.item?.uuid,
                      name: buildMessageBundle(name, nameLocales),
                      description: buildMessageBundle(
                        description,
                        descriptionLocales
                      ),
                      image: cover,
                      availability: availability,
                      pricing: pricing,
                    });
                  }
                }}
              >
                save
              </NavLink>
            </Grid>
          </ThemeProvider>
        </Grid>
      </Modal>
    );
  } else {
    return React.cloneElement(props.children, {
      onClick: () => setVisible(true),
    });
  }
};

export default ManageItemDialog;
